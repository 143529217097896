<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Selecionar prefeituras que não responderam pesquisa
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row class="mb-3">
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="surveyId"
              >
                <v-select
                  v-model="selectedSurvey"
                  item-value="id"
                  item-text="title"
                  label="Pesquisa"
                  hide-details="auto"
                  :items="surveys"
                  :error-messages="errors"
                  :loading="loadingSurveys"
                  @change="surveySelected"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import surveysApi from '@/api/surveys'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        loading: false,
        loadingSurveys: false,
        surveys: [],
        selectedSurvey: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },


    mounted () {
      this.loadSurveys()
    },

    methods: {

      async loadSurveys () {
        try {
          this.loadingSurveys = true

          const response = await surveysApi.list({
            onlyOpen: true,
            limit: 1000,
            offset: 0,
          })

          this.surveys = response.data.surveys
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSurveys = false
        }
      },

      async surveySelected (surveyId) {
        try {
          this.loading = true

          const response = await surveysApi.citiesThatDidNotRespond(surveyId)

          this.$emit('survey-selected', response.data.cities)
          this.show = false
          this.selectedSurvey = null
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      }

    },

  }
</script>

